<template>
  <div class="orthopaedic">
    <router-link to="/"><img src="@/assets/img/goback_icon.svg" class="gobackBtn"></router-link>
    <el-carousel :autoplay="false" arrow="always" @change="changeSwiper">
      <el-carousel-item>
        <video width="100%" height="100%" controls id="video1" muted webkit-playsinline="true" playsinline="true" x5-video-player-type="h5-page" x5-video-orientation="landscape|portrait" poster="@/assets/img/video1_poster.jpg">
          <source src="@/assets/video/video1.mp4" type="video/mp4">
        </video>
      </el-carousel-item>
      <el-carousel-item>
        <video width="100%" height="100%" controls id="video2" muted webkit-playsinline="true" playsinline="true" x5-video-player-type="h5-page" x5-video-orientation="landscape|portrait" poster="@/assets/img/video2_poster.jpg">
          <source src="@/assets/video/video2.mp4" type="video/mp4">
        </video>
      </el-carousel-item>
    </el-carousel>

    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="HoloSight" name="first">
        <HoloSightArticle></HoloSightArticle>
      </el-tab-pane>
      <el-tab-pane label="3D bone" name="second">
        <BoneArticle></BoneArticle>
      </el-tab-pane>
      <el-tab-pane label="医疗术式" name="third">
        <div v-for="(item,i) in medicalList" :key="i" class="medicalList" @click="jump(item.id)">
          <img :src="item.imgUrl" class="pic">
          <div class="details">
            <p class="title">{{item.title}}</p>
            <p class="date">{{item.creatDate}}</p>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>

    <img src="@/assets/img/returnTop.svg" class="returnTop" @click="toTop">
  </div>
</template>
<script>
import HoloSightArticle from "@/views/Orthopaedic/HoloSightArticle.vue";
import BoneArticle from "@/views/Orthopaedic/BoneArticle.vue";
import { articleList } from '@/api/getData'
export default {
  name: "Orthopaedic",
  data() {
    return {
      activeName: 'first',
      medicalList: [

      ],
      myTimer: -1
    }
  },
  components: {
    HoloSightArticle,
    BoneArticle
  },
  methods: {
    toTop() {
      let scrollTo = document.documentElement.scrollTop;
      if (this.myTimer == -1) {
        if (scrollTo > 10000) {
          let num = (scrollTo / 80).toFixed(0);
          this.myTimer = setInterval(() => {
            scrollTo -= num;
            if (scrollTo <= 0) {
              scrollTo = 0;
              window.clearInterval(this.myTimer);
              this.myTimer = -1;
            }
            window.scrollTo(0, scrollTo);
          }, 10);
        } else {
          this.myTimer = setInterval(() => {
            scrollTo -= 100;
            if (scrollTo <= 0) {
              scrollTo = 0;
              window.clearInterval(this.myTimer);
              this.myTimer = -1;
            }
            window.scrollTo(0, scrollTo);
          }, 10);
        }
      }
    },
    changeSwiper(newIndex, oldIndex) {
      let video1 = document.getElementById("video1");
      let video2 = document.getElementById("video2");
      if (newIndex == 1) {
        video1.pause()
        video2.play()
      } else {
        video1.play()
        video2.pause()
      }
    },
    autoPlay() {
      WeixinJSBridge.invoke('getNetworkType', {}, function (e) {
        let video = document.getElementById("video1");
        video.play()
      })
    },
    jump(id) {
      this.$router.push({
        path: '/medicalDetails',
        query: {
          articleId: id
        }
      })
    },
    handleClick(tab, event) {
    },
    getArticleList() {
      articleList().then(res => {
        this.medicalList = res.result
      }).catch(error => {
        console.log(error)
      })
    }
  },
  mounted() {
    this.getArticleList()
    // this.autoPlay()
    if (window.WeixinJSBridge) {
      console.log(1)
      this.autoPlay()
    } else {
      // document.addEventListener("WeixinJSBridgeReady", function () {
      // this.autoPlay()
      // }, false);
      let video = document.getElementById("video1");
      video.play()
    }
  }
}
</script>

<style lang="scss" scoped>
.orthopaedic {
  video {
    object-fit: fill;
    object-position: center;
  }
  .gobackBtn {
    position: absolute;
    left: 0.16rem;
    top: 0.16rem;
    z-index: 10;
    display: block;
    width: 0.31rem;
    height: 0.31rem;
  }
  :deep(.el-carousel__container) {
    height: 2.1rem;
    img {
      display: block;
      width: 100%;
      height: 100%;
    }
  }
  :deep(.el-tabs) {
    .el-tabs__header {
      margin: 0;
      .el-tabs__nav {
        float: none;
      }
    }
    .el-tabs__content {
      padding: 0 0.16rem;
      .el-tab-pane {
        margin-bottom: 0.1rem;
      }

      h2 {
        font-size: 0.18rem;
        margin-top: 0.24rem;
      }

      .date {
        font-size: 0.1rem;
        margin-top: 0.06rem;
        color: rgba(0, 0, 0, 0.4);
      }

      .content {
        img {
          display: block;
          margin: 0.2rem auto;
          border-radius: 0.08rem;
          width: 100%;
        }
        p {
          margin-top: 0.2rem;
          font-size: 0.14rem;
          line-height: 0.24rem;
          span {
            color: #34aabd;
          }
        }
        h3 {
          margin-top: 0.3rem;
          background: url("/articleImg/title_bg.png") no-repeat center;
          height: 0.52rem;
          line-height: 0.52rem;
          text-align: center;
          color: #0596ad;
          font-size: 0.18rem;
        }
        .center {
          text-align: center;
        }
        .footnote {
          height: 0.18rem;
          display: flex;
          align-items: center;
          font-size: 0.14rem;
          margin: 0 0 0.36rem 0.48rem;
          color: rgba(0, 0, 0, 0.85);

          i {
            width: 0.18rem;
            height: 0.18rem;
            text-align: center;
            line-height: 0.18rem;
            font-size: 0.1rem;
            font-style: normal;
            border-radius: 50%;
            margin-right: 0.12rem;
            color: #ffffff;
            background-color: #24bbd3;
          }
        }
        .mt36 {
          margin-top: 0.36rem;
        }
        .mb48 {
          margin-bottom: 0.48rem;
        }
        .widthAuto {
          width: 2.5rem;
          border-radius: 0;
        }
      }
      .medicalList {
        display: flex;
        height: 1rem;
        border-radius: 8px;
        background: #ffffff;
        box-shadow: 3px 4px 5px 0px rgba(255, 255, 255, 0.3),
          0px 3px 5px 3px rgba(50, 111, 121, 0.05),
          0px 0px 12px 0px rgba(112, 214, 231, 0.08);
        margin-top: 0.24rem;
        padding: 0.12rem;
        box-sizing: border-box;
        .pic {
          display: block;
          width: 1rem;
          height: 0.78rem;
          border-radius: 2px;
          margin-right: 0.16rem;
        }
        .details {
          flex: 1;
          .title {
            word-break: break-all;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            font-size: 0.13rem;
            line-height: 0.24rem;
            min-height: 0.5rem;
          }
          .date {
            color: rgba(0, 0, 0, 0.4);
            font-size: 0.1rem;
            margin-top: 0.14rem;
          }
        }
      }
    }
  }
  .returnTop {
    position: fixed;
    right: 0.1rem;
    bottom: 0.7rem;
  }
}
</style>